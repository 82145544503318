import React from "react";
import { Link } from "gatsby";

const ProjectCard = ({ data,slug }) => {
  const { image, title, url, date } = data;
  return (
    <div className="latestBlogItem">
      <div className="lbi_thumb">
        <img src={image} alt={title} />
      </div>
      <div className="lbi_details">
        {/* <Link className="lbid_date" to={url}>
          {date}
        </Link> */}
        <h4>
          <Link to={slug}>{title}</Link>
        </h4>
        <p>{data.description}</p>

        <Link className="learnM" to={slug}>
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
